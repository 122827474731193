import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'gatsby';
import { EyeOutlined, MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Space, Col, Select, Alert, Input, Menu, Button, Modal, Popover,message } from 'antd';
import * as utils from '../utils';
import LOGO_URL from '../../images/logo_ant.png';

const { Option } = Select;


const key = 'antd-pro@2.0.0-notification-sent';

let docSearch: (config: any) => void;
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  docSearch = require('docsearch.js');
}

const info = () => {
  message.info('This is a normal message');
};

function initDocSearch(locale: 'zh-CN' | 'en-US') {
  if (!docSearch) {
    return;
  }
  const lang = locale === 'zh-CN' ? 'cn' : 'en';
  /**docSearch({
    apiKey: 'dfba5eddecb719460b9fd232af57748d',
    indexName: 'pro_ant_design',
    inputSelector: '#search-box input',
    algoliaOptions: { facetFilters: [`tags:${lang}`] },
    transformData(
      hits: {
        url: string;
      }[],
    ) {
      hits.forEach((hit) => {
        // eslint-disable-next-line  no-param-reassign
        hit.url = hit.url.replace('ant.design.pro', window.location.host);
        // eslint-disable-next-line  no-param-reassign
        hit.url = hit.url.replace('https:', window.location.protocol);
      });
      return hits;
    },
    debug: false, // Set debug to true if you want to inspect the dropdown
  });*/
}

interface HeaderProps {
  isMobile: boolean;
  intl: any;
  location: {
    pathname: string;
  };
}
interface HeaderState {
  inputValue?: string;
  menuVisible: boolean;
  menuMode?: 'horizontal' | 'inline'|'vertical' | 'vertical-left' | 'vertical-right' ;
  searchOption?: any[];
  searching?: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
  state: HeaderState = {
    menuVisible: false,
    menuMode: 'horizontal',
  };

  searchInput: Input | null | undefined;

  timer: number;

  componentDidMount() {
    const { searchInput } = this;
    const { intl } = this.props;

    document.addEventListener('keyup', (event) => {
      if (event.keyCode === 83 && event.target === document.body) {
        if (searchInput) {
          searchInput.focus();
        }
      }
    });
    initDocSearch(intl.locale);

    if (localStorage.getItem(key) !== 'true' && Date.now() < new Date('2018/9/5').getTime()) {
      this.infoNewVersion();
    }

    const {
      intl: { locale },
    } = this.props;
    initDocSearch(locale);
  }

  componentDidUpdate(preProps: HeaderProps) {
    const { isMobile } = this.props;
    if (isMobile !== preProps.isMobile) {
      this.setMenuMode(isMobile);
    }

  }

  setMenuMode = (isMobile: boolean) => {
    console.log(isMobile,'------------isMobile')
    this.setState({ menuMode: isMobile ? 'inline' : 'horizontal' });
  };

  handleHideMenu = () => {
    this.setState({
      menuVisible: false,
    });
  };

  handleShowMenu = () => {
    this.setState({
      menuVisible: true,
    });
  };

  onMenuVisibleChange = (visible: boolean) => {
    this.setState({
      menuVisible: visible,
    });
  };

  handleSelect = (value: string) => {
    window.location.href = value;
  };

  infoNewVersion = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    Modal.info({
      title: formatMessage({ id: 'app.publish.title' }),
      content: (
        <div>
          <img
            src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
            alt="Ant Design"
          />
          <p>
            {formatMessage({ id: 'app.publish.greeting' })}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={formatMessage({ id: 'app.publish.url' })}
            >
              Ant Desgin Pro {formatMessage({ id: 'app.publish.intro' })}
            </a>
            {formatMessage({ id: 'app.publish.tips' })}
            {formatMessage({ id: 'app.publish.old-version-guide' })}
            <a target="_blank" rel="noopener noreferrer" href="https://v1.pro.ant.design">
              v1.pro.ant.design
            </a>
            {formatMessage({ id: 'app.publish.old-version-tips' })}
          </p>
        </div>
      ),
      okText: 'OK',
      onOk: () => localStorage.setItem(key, 'true'),
      className: 'new-version-info-modal',
      width: 470,
    });
  };

  handleLangChange = () => {
    const {
      location: { pathname },
    } = this.props;
    const currentProtocol = `${window.location.protocol}//`;
    const currentHref = window.location.href.substr(currentProtocol.length);

    if (utils.isLocalStorageNameSupported()) {
      localStorage.setItem('locale', utils.isZhCN(pathname) ? 'en-US' : 'zh-CN');
    }
    window.location.href =
      currentProtocol +
      currentHref.replace(
        window.location.pathname,
        utils.getLocalizedPathname(pathname, !utils.isZhCN(pathname)),
      );
  };

  onVersionChange = (value: string) => {
    if (value === 'v1') {
      //window.open('https://v1.pro.ant.design/');
    }
    if (value === 'v2') {
      //window.open('https://v2-pro.ant.design/');
    }
  };

  render() {
    const { menuMode, menuVisible } = this.state;
    const { location, intl } = this.props;
    const path = location.pathname;

    const module = location.pathname
      .replace(/(^\/|\/$)/g, '')
      .split('/')
      .slice(0, -1)
      .join('/');
    let activeMenuItem = module || 'home';
    if (/^blog/.test(path)) {
      activeMenuItem = 'blog';
    } else if (/docs/.test(path)) {
      activeMenuItem = 'docs';
    } else if (path === '/') {
      activeMenuItem = 'home';
    }else if (path === '/ecosphere-cn') {
      activeMenuItem = 'ecosphere';
    }else if (path === '/authorization-cn') {
      activeMenuItem = 'authorization';
    }else if (path === '/aboutus-cn') {
      activeMenuItem = 'aboutus';
    }
    console.log('------activeMenuItem--------------',activeMenuItem)
    console.log('------path--------------',path)

    const isZhCN = intl.locale === 'zh-CN';
    console.log(menuMode,'------menumode--------------')
    const menu = [
      <Menu mode={menuMode} selectedKeys={[activeMenuItem]} id="nav" key="nav">
        <Menu.Item key="home">
          <Link to={utils.getLocalizedPathname('/', isZhCN)}>
             首页
          </Link>
        </Menu.Item>
        <Menu.Item key="docs">
          <Link to={utils.getLocalizedPathname('/docs/getting-started', isZhCN)}>
            在线文档
          </Link>
        </Menu.Item>
        <Menu.Item key="blog">
          <Link target = "_blank" to={'https://ad.angryant.vip/'}>
           在线演示
          </Link>
        </Menu.Item>
        <Menu.Item key="ecosphere">
          <Link to={utils.getLocalizedPathname('/ecosphere/', isZhCN)}>
           应用生态
          </Link>
        </Menu.Item>
        <Menu.Item key="authorization">
          <Link to={utils.getLocalizedPathname('/authorization/', isZhCN)}>
           商业授权
          </Link>
        </Menu.Item>
        <Menu.Item key="aboutUs">
          <Link to={utils.getLocalizedPathname('/aboutus/', isZhCN)}>
           关于我们
          </Link>
        </Menu.Item>
      </Menu>,
    ];

    return (
      <div>
        <div id="header" className="header">
          {menuMode === 'inline' ? (
            <Popover
              overlayClassName="popover-menu"
              placement="bottomRight"
              content={menu}
              trigger="click"
              visible={menuVisible}
              arrowPointAtCenter
              onVisibleChange={this.onMenuVisibleChange}
            >
              <MenuOutlined className="nav-phone-icon" onClick={this.handleShowMenu} />
            </Popover>
          ) : null}
          <Row>
            <Col xxl={4} xl={5} lg={8} md={8} sm={24} xs={24}>
              <Link id="logo" to="/">
                <img src={LOGO_URL} alt="logo" />
                <span>广州好掌信息科技有限公司</span>
              </Link>
            </Col>
            <Col xxl={20} xl={19} lg={16} md={16} sm={0} xs={0}>
              <div className="header-meta">
                <div className="right-header">
                </div>
                {menuMode == 'horizontal' ? <div style={{minWidth:'600px'}} id="menu">{menu}</div> : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default injectIntl(Header);
